@tailwind base;
@tailwind components;
@tailwind utilities;

/* ================================================================
 * Styles that we should add to the tailwind theme - as an example
 * ================================================================ */
:root {
	--font-chime-saans-text: 'Chime Saans Text', sans-serif;
}

html,
body {
	padding: 0;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	font-family: var(--font-chime-saans-text);
	width: 100vw;
}

body {
	overflow-x: hidden;
}

#__next {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

@font-face {
	font-family: 'Chime Saans Text';
	font-style: normal;
	font-display: fallback;
	src:
		local('Chime Saans Text Medium'),
		local('ChimeSaansText-Medium'),
		/* IE6-IE8 */ url('/fonts/ChimeSaansText-Medium.woff2') format('woff2'),
		/* Super Modern Browsers */ url('/fonts/ChimeSaansText-Medium.woff') format('woff'); /* Modern Browsers */
}

@font-face {
	font-family: 'Chime Saans Text';
	font-style: normal;
	font-weight: 670;
	font-display: fallback;
	src:
		local('Chime Saans Text SemiBold'),
		local('ChimeSaansText-SemiBold'),
		/* IE6-IE8 */ url('/fonts/ChimeSaansText-SemiBold.woff2') format('woff2'),
		/* Super Modern Browsers */ url('/fonts/ChimeSaansText-SemiBold.woff') format('woff'); /* Modern Browsers */
}

@layer utilities {
	.inset-reset {
		top: unset;
		right: unset;
		bottom: unset;
		left: unset;
	}
}
